<template>
  <div class="flex-col">
    <WsInput
      v-model="localResponseData.text"
      aid="SURVEY_RESPONSE_TEXT_INPUT"
      size="lg"
      :placeholder="strings.textPlaceholder"
    />
    <WsButton
      v-if="collectResponderEmail"
      aid="SURVEY_RESPONSE_NEXT_BUTTON"
      label="Next"
      color="primary"
      size="lg"
      :disabled="!localResponseData.text"
      @click="handleNext"
    ></WsButton>
    <WsButton
      v-else
      aid="SURVEY_RESPONSE_SUBMIT_BUTTON"
      label="Submit"
      color="primary"
      size="lg"
      :disabled="!localResponseData.text"
      @click="emit('submit', localResponseData)"
    ></WsButton>
  </div>
</template>

<script setup lang="ts">
import { WsInput, WsButton } from '@mfl/common-components';
import { ResponseData } from './types';
import strings from './survey-response.strings';
import { ref } from 'vue';
import {
  surveyResponseGateway,
  SurveyType,
} from '@msl/survey-response-gateway-sdk';

const { responseData, collectResponderEmail } = defineProps<{
  responseData: ResponseData;
  collectResponderEmail: boolean;
}>();

const emit = defineEmits(['submit', 'update:text']);

const localResponseData = ref({ ...responseData });

const handleNext = async () => {
  await surveyResponseGateway.update({
    id: responseData.responseId,
    text: localResponseData.value.text,
    surveyType:
      SurveyType[localResponseData.value.surveyType as keyof typeof SurveyType],
  });
  emit('update:text', localResponseData.value.text);
};
</script>

<style scoped>
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  padding: 12px 24px;
}

label {
  width: 560px;
}

@media screen and (max-width: 560px) {
  label {
    width: 90vw;
  }
}
</style>
