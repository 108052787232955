<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { deferLoading, loadingDone } from '@mfl/framework';
import { WsButton } from '@mfl/common-components';
import {
  SourceType,
  surveyResponseGateway,
  SurveyType,
} from '@msl/survey-response-gateway-sdk';

import strings from './survey-response.strings';
import LayoutScore from './layout-score.vue';
import LayoutReview from './layout-review.vue';
import { LayoutStates, ResponseData } from './types';
import LayoutCollectEmail from './layout-collect-email.vue';

const mappedSourceType = Object.fromEntries(
  Object.keys(SourceType).map((key) => [key, key])
);

const mappedSurveyType = Object.fromEntries(
  Object.keys(SurveyType).map((key) => [key, key])
);

const scoreRanges: Record<string, { min: number; max: number }> = {
  csat: { min: 1, max: 5 },
  nps: { min: 0, max: 10 },
};

const defaultScoreRange = scoreRanges.nps;
const encodedSearch = window.location.search
  .split('&')
  .map((param) => {
    const [key, value] = param.split('=');
    return `${key}=${encodeURIComponent(decodeURIComponent(value))}`;
  })
  .join('&');
const urlParams = new URLSearchParams(encodedSearch);

const surveyId = window.location.pathname.split('/').pop();

const getInitialLayout = (): LayoutStates => {
  if (localStorage.getItem(`score${surveyId}`)) {
    return localStorage.getItem(`text${surveyId}`)
      ? LayoutStates.collectEmail
      : LayoutStates.text;
  }
  return LayoutStates.score;
};

const layout = ref<LayoutStates>(getInitialLayout());
const logo = ref<string | undefined>('');
const isLoading = ref<boolean>(true);
const collectResponderEmail = ref<boolean>(false);

const paramMap = {
  responderEmail: 'customer_email',
  companyName: 'company_name',
  companySize: 'company_size',
  plan: 'plan',
  teamMemberEmail: 'team_member_email',
  teamMemberFirstName: 'team_member_first_name',
  teamMemberLastName: 'team_member_last_name',
  externalTicketId: 'ticket_id',
  csm: 'csm',
  externalCustomerId: 'customer_id',
  externalTicketSubject: 'ticket_subject',
  customerFirstName: 'customer_first_name',
  customerLastName: 'customer_last_name',
  externalTicketGroupName: 't_group',
  campaign: 't_campaign',
};

const getUrlParam = (param: string) => urlParams.get(param) || undefined;

const responseData = reactive<ResponseData>({
  responseId: localStorage.getItem(`responseId${surveyId}`) || '',
  score:
    parseInt(localStorage.getItem(`score${surveyId}`) || 'NaN') || undefined,
  text: localStorage.getItem(`text${surveyId}`) || '',
  surveyType: '',
  responderEmail: localStorage.getItem(`responderEmail${surveyId}`) || '',
});

const updateLayoutAndLocalStorage = (
  text?: string,
  score?: number,
  responderEmail?: string
) => {
  layout.value =
    collectResponderEmail.value && layout.value === LayoutStates.text
      ? LayoutStates.collectEmail
      : text && layout.value !== LayoutStates.score
        ? LayoutStates.final
        : LayoutStates.text;
  if (text) {
    localStorage.setItem(`text${surveyId}`, text);
    responseData.text = text;
  }
  if (score !== undefined)
    localStorage.setItem(`score${surveyId}`, score.toString());
  if (responderEmail) {
    localStorage.setItem(`responderEmail${surveyId}`, responderEmail);
    responseData.responderEmail = responderEmail;
  }
};

const submit = async (updatedResponseData?: ResponseData) => {
  try {
    const { score, text, responseId, surveyType, responderEmail } =
      updatedResponseData ?? responseData;
    await surveyResponseGateway.update({
      id: responseId,
      score,
      text,
      surveyType: SurveyType[surveyType as keyof typeof SurveyType],
      responderEmail,
      submit: collectResponderEmail.value ? !!responderEmail : !!text,
    });
    updateLayoutAndLocalStorage(text, score, responderEmail);
  } catch (_err) {
    console.error('Error submitting response. Try again later.');
  }
};

const fetchSurveyType = async () => {
  try {
    const res = await surveyResponseGateway.getSurvey({ surveyId });
    if (res.statusCode !== 0 || res.type === undefined) {
      console.error('Survey is not defined.');
      responseData.surveyType = '';
      return;
    }
    logo.value = res.logo;
    responseData.surveyType = SurveyType[res.type];
    responseData.deactivated = !!res.deactivated;
    collectResponderEmail.value = !!res.collectResponderEmail;
  } catch (_err) {
    console.error('Error fetching survey type. Try again later.');
    responseData.surveyType = '';
  } finally {
    isLoading.value = false;
  }
};

const createResponse = async () => {
  try {
    if (
      responseData.deactivated ||
      responseData.surveyType === '' ||
      responseData.responseId
    )
      return;

    const responseDataWithParams: Record<string, unknown> = {
      surveyId,
      surveyType: responseData.surveyType,
      surveySource: getSurveySource(),
      ...Object.fromEntries(
        Object.keys(paramMap).map((key) => [
          key,
          getUrlParam(paramMap[key as keyof typeof paramMap]),
        ])
      ),
    };

    const response = await surveyResponseGateway.create(responseDataWithParams);
    responseData.responseId = response.id || '';
    localStorage.setItem(`responseId${surveyId}`, responseData.responseId);
  } catch (_err) {
    console.error(_err);
  }
};

const calculateScore = () => {
  if (responseData.surveyType === undefined) return;

  const { min, max } =
    scoreRanges[responseData.surveyType] || defaultScoreRange;
  const queryScore = getUrlParam('rating');
  if (!queryScore || +queryScore < min || +queryScore > max) return;
  return parseInt(queryScore, 10) ?? undefined;
};

const getSurveySource = () => {
  const surveySource = getUrlParam('source') || mappedSourceType.link;
  return (
    mappedSourceType[surveySource as keyof typeof mappedSourceType] ??
    mappedSourceType.invalid
  );
};

const handleRate = async (star: number) => {
  responseData.score = star;
  await submit();
  if (urlParams.has('rating')) {
    urlParams.set('rating', star.toString());
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}?${urlParams.toString()}`
    );
  }
};

onMounted(async () => {
  try {
    deferLoading();
    await surveyResponseGateway.init();
    await fetchSurveyType();
    const allQuestionsAnswered =
      responseData.text &&
      responseData.score &&
      (responseData.responderEmail || !collectResponderEmail.value);
    if (allQuestionsAnswered) {
      layout.value = LayoutStates.answered;
      return;
    }

    if (!responseData.responseId) await createResponse();

    const queryScore = calculateScore();
    if (queryScore !== undefined) {
      responseData.score = queryScore;
      localStorage.setItem(`score${surveyId}`, queryScore.toString());
      layout.value = LayoutStates.text;
      await submit();
    }
  } finally {
    loadingDone();
  }
});
</script>

<template>
  <div v-if="isLoading" class="loader-container">
    <span id="frame-loader" aria-hidden="true" />
  </div>
  <WsButton
    v-if="
      responseData.surveyType !== '' &&
      (layout === LayoutStates.text || layout === LayoutStates.collectEmail)
    "
    icon="fa-regular fa-chevron-left"
    label="Back"
    class="back"
    color="gray-500"
    variant="text"
    aid="BACK_BUTTON"
    @click="
      () =>
        (layout =
          layout === LayoutStates.text ? LayoutStates.score : LayoutStates.text)
    "
  />
  <div
    v-if="responseData.surveyType === '' && !isLoading"
    class="empty-state-container"
  >
    <img
      class="empty-state-logo"
      alt="survey response"
      src="./assets/ws-logo.svg"
    />
    <span class="empty-state-header">{{ strings.emptyStateHeader }}</span>
  </div>
  <div
    v-else-if="responseData.surveyType !== undefined && !isLoading"
    class="survey-container"
  >
    <img :src="logo" :alt="strings.logoAlt" class="logo" />
    <h4 class="header">
      {{
        layout === LayoutStates.final || layout === LayoutStates.answered
          ? strings[`${LayoutStates[layout]}Header` as keyof object]
          : strings[
              `${LayoutStates[layout]}Header${responseData.surveyType.toUpperCase()}` as keyof object
            ]
      }}
    </h4>
    <layout-score
      v-if="layout === LayoutStates.score"
      :response-data="responseData"
      :mapped-survey-type="mappedSurveyType"
      @handle-rate="handleRate"
    >
    </layout-score>
    <layout-review
      v-else-if="layout === LayoutStates.text"
      :response-data="responseData"
      :collect-responder-email="collectResponderEmail"
      :layout="layout"
      @update:layout="layout = $event"
      @submit="submit"
      @update:text="updateLayoutAndLocalStorage($event)"
    ></layout-review>
    <layout-collect-email
      v-else-if="layout === LayoutStates.collectEmail"
      :response-data="responseData"
      @submit="submit"
    />
    <footer>
      Powered by
      <img src="./assets/ws-logo.svg" :alt="strings.logoAlt" />
    </footer>
  </div>
</template>

<style scoped>
.back {
  position: absolute;
  margin-top: 24px;
  margin-left: 24px;
  transition-duration: 0.3s;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-state-logo {
    display: block;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    width: 370px;
    height: 244px;
  }

  .empty-state-header {
    font-size: 20px;
    font-weight: 700;
  }
}

.survey-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  text-align: center;
  height: 100vh;
  justify-content: center;

  .logo {
    width: 120px;
    height: 120px;
    border-radius: 100px;
    background-color: white;
    color: white;
  }

  .header {
    font-size: 26px;
    font-weight: 600;
  }

  button {
    padding: 12px 24px;
  }

  label {
    width: 560px;
  }

  footer {
    text-align: center;
    position: absolute;
    bottom: 40px;
  }

  @media screen and (max-width: 420px) {
    .header {
      font-size: 23px;
    }
  }

  @media screen and (max-width: 560px) {
    label {
      width: 90vw;
    }
  }
}
</style>
