<template>
  <div class="flex-col">
    <div v-if="responseData.surveyType === mappedSurveyType.csat">
      <div class="flex stars">
        <div
          v-for="star in 5"
          :key="star"
          class="star-wrapper"
          @click="emits('handleRate', star)"
        >
          <div
            :class="[
              'star',
              responseData.score && star <= responseData.score
                ? 'active-star'
                : '',
            ]"
          ></div>
          <span>{{ star }}</span>
        </div>
      </div>
      <div class="scat scale">
        <div>{{ strings.veryUnsatisfied }}</div>
        <div>{{ strings.verySatisfied }}</div>
      </div>
    </div>
    <div v-if="responseData.surveyType === mappedSurveyType.nps">
      <div class="flex">
        <div
          v-for="square in 11"
          :key="square - 1"
          :class="['square', square - 1 === responseData.score ? 'active' : '']"
          @click="emits('handleRate', square)"
        >
          {{ square - 1 }}
        </div>
      </div>
      <div class="scale">
        <div>{{ strings.leastLikely }}</div>
        <div>{{ strings.neutral }}</div>
        <div>{{ strings.mostLikely }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ResponseData } from './types';
import strings from './survey-response.strings';
const emits = defineEmits(['handleRate']);

const { responseData, mappedSurveyType } = defineProps<{
  responseData: ResponseData;
  mappedSurveyType: Record<string, unknown>;
}>();
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scale {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #828282;
  font-size: 13px;
  padding: 40px 0;
}

.scat {
  padding: 8px 20px;
}

.stars {
  margin-left: 44px;
}

.square {
  margin: 0 5px 0 0;
  text-align: center;
  cursor: pointer;
  border-radius: 0.33px;
  width: 60px;
  height: 60px;
  background: #f4f6f8;
  transition-duration: 0.3s;
  color: #2d2e30;
  font-size: 28px;
  border: 0.83px solid #edeeef;
  align-content: center;
  font-weight: 700;

  &&:hover {
    background: #e7e7e7;
  }
}

.active {
  background: #aaafb4;

  &&:hover {
    background: #aaafb4;
  }
}

.star-wrapper {
  padding: 0 40px 0 0;
  text-align: center;
  cursor: pointer;

  .star {
    width: 60px;
    height: 60px;
    background: #e0e0e0;
    mask-image: url(./assets/star.svg);
    mask-repeat: no-repeat;
    mask-position: top right;
    transition-duration: 0.3s;
  }

  span {
    color: #828282;
    font-weight: 400;
    font-size: 28px;
    transition-duration: 0.3s;
  }

  &&:has(~ .star-wrapper:hover) .star,
  &&:hover .star,
  + .star,
  .active-star {
    background: #ffd527;

    span,
    + span {
      color: black;
    }
  }
}

@media screen and (max-width: 420px) {
  .header {
    font-size: 23px;
  }

  .stars {
    margin-left: 0px;

    span {
      font-size: 22px;
    }
  }
  .star-wrapper {
    padding: 0 10px 0 0;

    .star {
      width: 50px;
      height: 50px;
      mask-image: url(./assets/star-s.svg);
    }
  }
  .square {
    width: 32px;
    height: 32px;
    margin: 0;
    font-size: 20px;
  }
}

@media screen and (min-width: 421px) and (max-width: 500px) {
  .stars {
    margin-left: 20px;
  }
  .star-wrapper {
    padding: 0 20px 0 0;

    .star {
      width: 55px;
      height: 55px;
      mask-image: url(./assets/star.svg);
    }
  }

  .square {
    width: 37px;
    height: 37px;
    margin: 0;
    font-size: 23px;
  }
}

@media screen and (min-width: 501px) and (max-width: 800px) {
  .stars {
    margin-left: 30px;
  }
  .star-wrapper {
    padding: 0 30px 0 0;

    .star {
      width: 55px;
      height: 55px;
      mask-image: url(./assets/star.svg);
    }
  }

  .square {
    width: 43px;
    height: 43px;
    font-size: 26px;
    margin: 0 2px 0 0;
  }
}

@media screen and (max-width: 560px) {
  label {
    width: 90vw;
  }
}
</style>
