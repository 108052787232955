export enum LayoutStates {
  score = 0,
  text = 1,
  final = 2,
  answered = 3,
  collectEmail = 4,
}

export interface ResponseData {
  score?: number;
  text?: string;
  surveyType: string;
  responseId: string;
  deactivated?: boolean;
  responderEmail?: string;
}
