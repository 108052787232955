<template>
  <div class="flex-col">
    <form @submit.prevent="handleSubmit">
      <WsInput
        v-model="localResponseData.responderEmail"
        aid="SURVEY_RESPONSE_RESPONDER_EMAIL_INPUT"
        size="lg"
        debounce="500"
        :placeholder="strings.textPlaceholder"
        :error="emailInvalidMessage"
        :rules="[validateEmail]"
      />
      <WsButton
        aid="SURVEY_RESPONSE_SUBMIT_BUTTON"
        label="Submit"
        type="submit"
        color="primary"
        size="lg"
        :disabled="!localResponseData.responderEmail"
      ></WsButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import { WsInput, WsButton } from '@mfl/common-components';
import { ResponseData } from './types';
import strings from './survey-response.strings';
import { ref } from 'vue';

const { responseData } = defineProps<{
  responseData: ResponseData;
}>();

const emit = defineEmits(['submit']);

const emailInvalidMessage = ref<string | undefined>(undefined);

const localResponseData = ref({ ...responseData });

function validateEmail(val: string) {
  if (!val) return false;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(val)) {
    emailInvalidMessage.value = strings.invalidEmail;
    return false;
  }
  emailInvalidMessage.value = undefined;
  return true;
}

function handleSubmit() {
  if (!emailInvalidMessage.value) {
    emit('submit', localResponseData.value);
  }
}
</script>

<style scoped>
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  padding: 12px 24px;
}

label {
  width: 560px;
}

@media screen and (max-width: 560px) {
  label {
    width: 90vw;
  }
}
</style>
